import React from 'react';
import styled from 'styled-components';
import { FormLabel } from '@uikit';

interface FieldsetProps {
  testID?: string;
  label?: string;
  sublabel?: string;
  name?: string;
  optional: boolean;
  children: React.ReactNode;
  ariaLabel?: string;
}

const StyledFieldset = styled.fieldset`
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 12px;
  padding: 16px;
`;

const Fieldset: React.FC<FieldsetProps> = ({
  testID,
  label,
  sublabel,
  name,
  optional,
  children,
  ariaLabel,
}) => {
  return (
    <StyledFieldset aria-label={ariaLabel || label}>
      <FormLabel
        testID={testID}
        label={label}
        asLegend={true} // Render label as <legend>
        sublabel={sublabel}
        optional={optional}
      />
      {children}
    </StyledFieldset>
  );
};

export default Fieldset;
